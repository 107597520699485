import React, { useEffect } from 'react';
import axios from 'axios';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Grid from '@mui/material/Unstable_Grid2';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import './index.css'
import { Paper } from '@mui/material';

export default function Index() {
    const [items, setItems] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [images, setImages] = React.useState([]);
    const [config, setConfig] = React.useState({
        display: { "title": "", "subtitle": "" },
        image_filters: []
    });
    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

    const [imageFilter, setImageFilter] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [form, setForm] = React.useState({
        'name': '',
        'email': '',
        'phone': '',
        'code': '',
        'message': '',
        'image': ''
    });
    const [error, setError] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [idle, setIdle] = React.useState(0);
    const [screenSaver, setScreenSaver] = React.useState(false);
    const [saverImg, setSaverImg] = React.useState('');
    const [saverImgBg, setsaverImgBg] = React.useState('');
    const [phoneHelp, setPhoneHelp] = React.useState('Move to next field to receive a code by phone');

    const formatMoney = (params) => {
        return '$ ' + parseFloat(params.value).toFixed(2);
    }

    const columns = [
        { field: 'number', headerName: 'Number', width: 150 },
        { field: 'description', headerName: 'Item Description', width: 360 },
        { field: 'qty', headerName: 'Qty', type: 'number' },
    ];

    useEffect(() => {
        getItems();

        window.addEventListener('resize', () => {
            console.log('resized to: ', window.innerWidth, 'x', window.innerHeight);
            setInnerWidth(window.innerWidth);
        })

        //idle
        window.idle = 0;
        window.images = images;
        window.imageFilter = '';
        clearInterval(window.interval);
        window.interval = setInterval(() => {
            secondInterval();
        }, 5000);
        ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(function (name) {
            document.addEventListener(name, () => {
                if (window.idle > 0) window.idle = 0;
            }, true);
        });

        return () => clearInterval(window.interval);
    }, [])

    const secondInterval = () => {
        console.log(window.idle);
        window.idle += 5;
        if (window.idle == 30) {
            setScreenSaver(true);
        }
        if (window.idle >= 10 && window.idle % 5 == 0) {

            let filtered = window.images;
            if (window.imageFilter != '') {
                filtered = window.images.filter((i) => (window.imageFilter == '' ? true : i.includes(window.imageFilter)))
            }

            let r = Math.floor(Math.random() * filtered.length);
            setSaverImg(filtered[r]);
        }
    }

    const getItems = () => {
        axios.get(process.env.REACT_APP_API_URL + '/data/all.php').then((response) => {
            //console.log(response.data);
            setItems(response.data.items);
            setImages(response.data.images);
            setConfig(response.data.config);
            window.images = response.data.images;
            window.document.title = response.data.config.display.title;
        });
    }

    const sendCode = () => {
        let v = document.getElementById('phone').value;
        console.log('send code', v);

        axios.post(process.env.REACT_APP_API_URL + '/send_code.php', { 'phone': v }).then((response) => {
            console.log(response.data);
            if ('error' in response.data) {
                setError(response.data.msg);
            }

            if ("phone" in response.data) {
                setPhoneHelp('Verification code sent to ' + response.data.phone);
                setError('');
            }
        })
    }

    const sendForm = (e) => {
        e.preventDefault();
        console.log('send form', form);

        setLoading(true);
        let api = new URL(process.env.REACT_APP_API_URL, document.baseURI).href;
        axios.post(process.env.REACT_APP_API_URL + '/contact.php', { ...form, api: api }).then((response) => {
            setLoading(false);
            console.log(response.data);
            if ('error' in response.data) {
                setError(response.data.msg);
            } else {
                //success
                setError(false);
                setOpen(false);
            }
        })

        return false;
    }

    return (
        <>
            <div className='pageBg'>
                <Container>
                    <div className='pageTitle'>{config.display.title}</div>
                    <div className='subTitle'>
                        {config.display.subtitle}
                    </div>
                    <div className='imageFilters'>
                        <ToggleButtonGroup size="small" exclusive
                            value={imageFilter}
                            onChange={(e, v) => { console.log("setImageFilter", v); setImageFilter(v); window.imageFilter = v; }}
                            aria-label="image filter"
                        >
                            {config.image_filters.map((item) => (
                                <ToggleButton value={item.value}>{item.label}</ToggleButton>
                            ))}
                        </ToggleButtonGroup>

                        <Button className="inventoryTableBtn" variant="outlined" size="small" href="#inventory">Inventory List</Button>
                    </div>
                </Container >
                <div style={{ margin: '8px' }}>

                    <ImageList cols={Math.max(innerWidth / 256, 2)} gap={8} variant="quilted">
                        <ImageListItem cols={2} rows={2} style={{ textAlign: 'center' }}>
                            <iframe class="youtube-video" width="560" height="315" src="https://www.youtube.com/embed/4f1IBKxCQX8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <br />
                            <Button variant="outlined" href="https://www.youtube.com/playlist?list=PL_olEJc11GciOM9tCqOu16A4WhDpq45J4">Vidéos de pieds de bateau en stock</Button>
                        </ImageListItem>
                        {images.filter((i) => (imageFilter == '' ? true : i.includes(imageFilter))).map((item) => (
                            <ImageListItem key={item} onClick={() => {
                                setOpen(true);
                                setForm({ ...form, image: item });
                            }}>
                                <div className='imgGrid' style={{ backgroundImage: 'url(' + process.env.REACT_APP_API_URL + '/' + item + ')' }}></div>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
            </div >
            <Paper style={{ margin: '8px', maxWidth: '940px', margin: '0 auto' }} id="inventory">
                <DataGrid rows={items} columns={columns}
                    disableColumnFilter disableColumnSelector disableDensitySelector density='compact' disableColumnMenu
                    hideFooterSelectedRowCount pageSizeOptions={[10000]} hideFooter
                    slots={{ toolbar: QuickSearchToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            total: total
                        },
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 10000, page: 0 },
                        },
                    }} />
            </Paper>
            <br />
            <Fab className="contactBtn" variant="extended" size="small" color="primary" aria-label="contact"
                onClick={() => { setOpen(true); setForm({ ...form, image: '' }); }}>
                Contact
            </Fab>
            <Dialog open={open} onClose={() => { setOpen(false) }}>
                <form onSubmit={sendForm} name="contact">
                    <DialogTitle>Contact Form</DialogTitle>
                    <DialogContent>

                        <Grid container spacing={1}>
                            <Grid xs={12} sm={6}><TextField
                                autoFocus
                                margin="dense" size="small" fullWidth InputLabelProps={{ shrink: true }} variant="outlined"
                                name="name"
                                label="Name"
                                type="text"
                                value={form.name} onChange={(e) => { setForm({ ...form, name: e.target.value }) }}
                            /></Grid>
                            <Grid xs={12} sm={6}><TextField
                                margin="dense" size="small" fullWidth InputLabelProps={{ shrink: true }} variant="outlined"
                                name="email"
                                label="Email"
                                type="email"
                                value={form.email} onChange={(e) => { setForm({ ...form, email: e.target.value }) }}
                            /></Grid>
                            <Grid xs={12} sm={9}><TextField
                                margin="dense" size="small" fullWidth InputLabelProps={{ shrink: true }} variant="outlined"
                                name="phone" id="phone"
                                label="Phone" helperText={phoneHelp}
                                type="tel" onBlur={sendCode}
                                value={form.phone} onChange={(e) => { setForm({ ...form, phone: e.target.value }) }}
                            /></Grid>
                            <Grid xs={6} sm={3} style={{ padding: '11.5px 16px' }} hidden>
                                <Button onClick={sendCode}>Send Code</Button>
                            </Grid>
                            <Grid xs={6} sm={3}><TextField
                                margin="dense" size="small" fullWidth InputLabelProps={{ shrink: true }} variant="outlined"
                                name="code"
                                label="Verification Code"
                                type="number"
                                value={form.code} onChange={(e) => { setForm({ ...form, code: e.target.value }) }}
                            /></Grid>
                            <Grid xs={12} sm={12}><TextField
                                margin="dense" size="small" fullWidth InputLabelProps={{ shrink: true }} variant="outlined"
                                name="message"
                                label="Message"
                                type="text"
                                multiline minRows={3}
                                value={form.message} onChange={(e) => { setForm({ ...form, message: e.target.value }) }}
                            /></Grid>
                            <Grid xs={3}>
                                {form.image ?
                                    <img
                                        src={process.env.REACT_APP_API_URL + '/' + form.image}
                                        alt={form.image}
                                        loading="lazy" style={{ width: '100%' }}
                                    /> : null}
                            </Grid>
                        </Grid>
                        {error ? <Alert severity="error">{error}</Alert> : null}
                    </DialogContent>
                    <DialogActions sx={{ pr: 3 }}>
                        <Button onClick={() => { setOpen(false) }}>Cancel</Button>
                        <Button variant="contained" type='submit' disabled={loading}>Send</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth='md'
                open={screenSaver}
                onClose={() => { setScreenSaver(false) }}
            >
                <div className='saverContainer'>
                    <img className='saverImg' src={process.env.REACT_APP_API_URL + '/' + saverImg} onClick={() => {
                        setOpen(true);
                        setForm({ ...form, image: saverImg });
                    }} />
                    <IconButton size="small" className='closeSaver' onClick={() => { setScreenSaver(false) }}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                </div>
            </Dialog>
        </>
    )
}

function QuickSearchToolbar(props) {
    //console.log(props)
    return (
        <Grid container spacing={1} sx={{ p: 0.5, pb: 0 }}>
            <Grid xs={12} sm={4}>
                <GridToolbarQuickFilter />
            </Grid>
            <Grid xs={12} sm={4} textAlign='center' sx={{ p: 1 }}>
                <Typography variant="subtitle2">
                    Informations: <b>5148007328@hotmail.com</b>
                </Typography>
            </Grid>
            <Grid xs={12} sm={4} textAlign='right' sx={{ p: 1 }}>
                <Typography variant="subtitle2">

                </Typography>
            </Grid>
        </Grid>
    );
}